/* Color Variables */
:root {
    --color-primary: #708c37;
    --color-info: #17a2b8;
    --color-warning: #ffc107;
    --color-error: #dc3545;
    --border-color: #e3e4e4;
    --border-color-dark: #c4c4c4;
    --font-color-default: #546e7a;
}

/* Typography Variables */
:root {
    /*--font-size-base: 16px;*/
    /*--heading-font-size: 24px;*/
}
