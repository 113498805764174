.tgf-tooltip {
    font-size: .9rem;
}

.tgf-tooltip p {
    margin: 4px;
}

.tgf-tooltip hr {
    padding: 0;
    margin: 2px;
}