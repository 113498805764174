.k-grid th {
  padding: .75rem .75rem;
  border-width: 0 0 1px 1px;
  white-space: normal;
}

.shipment-history-styles {
  .MuiDivider-root {
    background-color: #784599 !important;
    height: 3px !important;
  }

  .shipment-available-credit {
    font-weight: 500;
    font-size: 14px;
    align-content: end;

    .shipment-available-credit-positive {
      color: #01a201;
    }
    .shipment-available-credit-negative {
      color: #F31700FF;
    }
  }

  .shipment-detail-filters {
    display: flex;

    > div {
      align-content: flex-end;
    }
    > div:nth-child(1) {
      width: 110px;
    }
    > div:nth-child(2) {
      width: 130px;
    }
    > div:nth-child(3) {
      min-width: 150px;
    }
    > div:nth-child(4) {
      flex: 1;
    }
  }
}
.shipment-history-control {
  height: 36px;
}

.shipment-totals {
  font-size: 14px;
  font-weight: 600;
}