.tgf-simple-table {
  border-collapse: collapse;
  width: 100%;
  color: var(--font-color-default);
  font-size: 12px;
}

.tgf-simple-table th {
  background-color: #f3f2f2;
  border: 1px solid #000;
  padding: 4px 8px;
  font-weight: 600;
}

.tgf-simple-table td {
  border: 1px solid #000;
  padding: 4px 8px;
  font-weight: 600;
}

.tgf-simple-table tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.tgf-simple-table tbody tr:nth-child(odd) {
  background-color: #fafafa;
}