.card-body span {
    // font-size: 1.25em;
}
.announcement-text p {
    margin: 0;
}

.tgf-footer {
    background-color: #708c368c;
    border-color: #708c36;
}

.tfg-text-muted {
    color: #000000e6 !important;
    font-weight: bolder;
}

.auto-invoice-count-link {
    color: green;
}

.halted-invoice-count-link {
    color: darkred;
}

.non-metric-card {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  align-content: center;
  flex: 1;

  > div:first-child {
    color: green;
    text-decoration: underline;
    margin-bottom: 5px;
  }
}