.all-time-highs-admin-listing {
  width: 100%;

  h5 {
    padding: 15px 0 10px 0;
  }

  > div {
    margin-top: 5px;
  }

  .agents-header {
    align-content: center;
  }
}

.all-time-highs-listing {

  width: 100%;

  h5 {
    padding: 15px 0 10px 0;
  }

}